
import ConfidenceIndicatorBadge from '@/components/shared/ConfidenceIndicatorBadge.vue';
import {
    formatDateTime,
    getPredictedOutcome,
    getRoundedPercentage,
    noPredictedAdmitStatusDisclaimer,
    predictedAdmitStatusBelowThresholdDisclaimer,
    predictedAdmitStatusIPODisclaimer,
} from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import PredictionDisclaimerIcon from '@/components/visit/conditions/PredictionDisclaimerIcon.vue';
import { Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'CurrentStatusPredictionTile',
    components: {
        ConfidenceIndicatorBadge,
        PredictionDisclaimerIcon,
        VerticalField,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
    },
    data: () => ({
        hover: false,
        noPredictedAdmitStatusDisclaimer,
        predictedAdmitStatusIPODisclaimer,
        predictedAdmitStatusBelowThresholdDisclaimer,
    }),
    computed: {
        predictedStatusText(): string {
            const { admitStatus, predictedAdmitStatus } = this.visit;

            let status = predictedAdmitStatus || '';
            if (admitStatus !== predictedAdmitStatus) {
                const change = getPredictedOutcome(admitStatus, status);
                status = `${status} ${change}`;
            }
            return status;
        },
        formattedDateTime(): string {
            return formatDateTime(this.visit.currentAdmitStatusPrediction?.datePredicted) || 'N/A';
        },
        lowPrediction(): Record<string, unknown> {
            const currentAdmitStatusPrediction: Record<string, unknown> = this.visit.currentAdmitStatusPrediction || {};
            const keys = Object.keys(currentAdmitStatusPrediction);
            const predictedCondition = keys.filter((k) => k !== 'datePredicted').find((k) => currentAdmitStatusPrediction[k]);

            if (!predictedCondition) return { confidenceValue: 0, status: 'Downgrade' };

            let predictedStatus = '';
            let confidenceValue = getRoundedPercentage(Number(currentAdmitStatusPrediction[predictedCondition]));

            switch (predictedCondition) {
                case 'inpatientConfidence':
                    predictedStatus = 'Inpatient';
                    break;
                case 'observationConfidence':
                    predictedStatus = 'Observation';
                    break;
                case 'dischargeConfidence':
                    predictedStatus = 'Discharge';
                    break;
            }

            if (predictedStatus === this.visit.admitStatus) {
                return { confidenceValue: 0, status: 'Upgrade' };
            } else {
                return {
                    confidenceValue,
                    status: getPredictedOutcome(this.visit.admitStatus, predictedStatus),
                };
            }
        },
        lowPredictionColor(): string {
            return this.lowPrediction.status === 'Upgrade' ? 'success95' : 'informational87';
        },
    },
});
