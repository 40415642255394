
import { visitPredictedStatusDisclaimer } from '@/components/shared/utils';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PredictionDisclaimerIcon',
    data: () => ({
        visitPredictedStatusDisclaimer,
    }),
});
