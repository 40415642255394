export class Routes {
    public static readonly WORKLIST = '/worklist';
    public static readonly LOGIN = '/login';
    public static readonly LOGIN_CALLBACK = '/login/callback';
    public static readonly ANALYTICS = '/analytics';
    public static readonly VISIT_VIEW = '/visit/:id';
    public static readonly ADMIN = '/admin';
    public static readonly CLINICAL_SUMMARY = 'clinicalsummary/';
    public static readonly CLINICAL_SUMMARY_ID = ':clinicalSummaryId';
    public static readonly QUERIES_VIEW = `queries/`;
    public static readonly APPEALS_DENIALS = `denials/`;
    public static readonly INBOX = `inbox/`;
}
