
import Loadable from '@/components/shared/Loadable.vue';
import { formatDateTime, formatTime, withDefault } from '@/components/shared/utils';
import LabsConditionDetailView from '@/components/visit/labs/LabsConditionDetailView.vue';
import { DrawerType, VisitTimelineEventDetails } from '@/models';
import { getLabVitalsResultsTable } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitTimelineStore } from '@/stores/VisitTimelineStore';
import { LabVitalsResultsTable, Maybe, Query } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitTimelineLabVitalsDetailViewMultiSelect',
    components: { LabsConditionDetailView, Loadable },
    props: {
        selectedEvent: {
            type: Object as PropType<VisitTimelineEventDetails>,
            required: true,
        },
    },
    data: () => ({
        isLoading: true,
        hover: false,
        category: '',
        labVitalsResultsTable: {} as LabVitalsResultsTable,
        visitTimelineStore: useVisitTimelineStore(),
        visitDrawerStore: useVisitDrawerStore(),
        featureStore: useFeatureStore(),
    }),
    computed: {
        header(): Maybe<string> | undefined {
            return this.selectedEvent.subHeader;
        },
        subheader(): string {
            return this.selectedEvent.header;
        },
        dateTime(): string {
            return `${formatDateTime(this.selectedEvent.timestamp)}`;
        },
        isViewTrendFeatureEnabled(): boolean {
            return this.featureStore.isEnabled('LAB_MED_HISTORY');
        },
    },
    watch: {
        $props: {
            immediate: true,
            deep: true,
            handler() {
                this.getLabVitalsResultTable(this.selectedEvent);
            },
        },
    },
    methods: {
        formatTime,
        withDefault,
        async getLabVitalsResultTable(event: VisitTimelineEventDetails): Promise<void> {
            this.isLoading = true;
            this.category = event.groupedByType ?? '';
            const response = await this.$apollo.query<Query>({
                query: getLabVitalsResultsTable,
                variables: {
                    visitId: parseInt(this.$route.params.id),
                    category: event.groupedByType,
                    filter: {
                        visitId: parseInt(this.$route.params.id),
                        category: event.groupedByType,
                        date: event.groupedByDate,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.labVitalsResultsTable = response.data.labVitalsResultsTable;
            this.isLoading = false;
        },
        closeItem(): void {
            this.visitTimelineStore.reset();
        },
        async openTrendView() {
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.LABS,
                params: {
                    categoryName: this.category,
                    highlightEntityIds: new Set<string>(),
                },
            });
        },
    },
});
