
import { Role } from '@/auth/role.enum';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import AdminButton from '@/components/sidebar/AdminButton.vue';
import AnalyticsButton from '@/components/sidebar/AnalyticsButton.vue';
import AvoidableDaysDialog from '@/components/sidebar/AvoidableDaysDialog.vue';
import CaseNotesButton from '@/components/sidebar/CaseNotesButton.vue';
import ClinicalSummaryButton from '@/components/sidebar/ClinicalSummaryButton.vue';
import ClinicalSummaryHistoryButton from '@/components/sidebar/ClinicalSummaryHistoryButton.vue';
import CompleteReviewDialog from '@/components/sidebar/CompleteReviewDialog.vue';
import CreateQueryButton from '@/components/sidebar/CreateQueryButton.vue';
import EscalateToPADialog from '@/components/sidebar/EscalateToPADialog.vue';
import InboxButton from '@/components/sidebar/InboxButton.vue';
import PatientSummaryButton from '@/components/sidebar/PatientSummaryButton.vue';
import QueriesButton from '@/components/sidebar/QueriesButton.vue';
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import WorklistButton from '@/components/sidebar/WorklistButton.vue';
import { InteractQueryTemplate } from '@/models/interact/interact.model';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import Vue from 'vue';
import AppealsDenialsButton from './AppealsDenialsButton.vue';

export default Vue.extend({
    name: 'SideBar',
    components: {
        AdminButton,
        AnalyticsButton,
        AppealsDenialsButton,
        AvoidableDaysDialog,
        CaseNotesButton,
        ClinicalSummaryButton,
        ClinicalSummaryHistoryButton,
        CompleteReviewDialog,
        CreateQueryButton,
        EscalateToPADialog,
        PatientSummaryButton,
        QueriesButton,
        SideBarButton,
        WorklistButton,
        FeatureToggle,
        InboxButton,
    },
    data: () => ({
        userStore: useUserStore(),
        featureStore: useFeatureStore(),
        visitStore: useVisitStore(),
        expanded: false,
        navButtonRoutes: new Set<string>([
            'visit',
            'patientsummary',
            'clinicalsummarylist',
            'clinicalsummarylistopen',
            'queries',
            'denials',
            'inbox',
        ]),
        toolEnabledRoutes: new Set<string>([
            'visit',
            'patientsummary',
            'clinicalsummarylist',
            'clinicalsummarylistopen',
            'queries',
            'denials',
            'inbox',
        ]),
        adminRoutes: new Set<string>(['worklist', 'admin', 'analytics']),
        worklistRoutes: new Set<string>(['worklist', 'admin', 'analytics']),
        analyticsEnabledRoutes: new Set<string>(['worklist', 'admin', 'analytics']),
        InteractQueryTemplate,
    }),
    computed: {
        displayTools(): boolean {
            return this.toolEnabledRoutes.has(this.$route.name ?? '');
        },
        isUmNurse(): boolean {
            return this.userStore.currentRole === Role.UM_NURSE;
        },
        isPhysicianAdvisor(): boolean {
            return this.userStore.currentRole === Role.PHYSICIAN_ADVISOR;
        },
        isInteractEnabled(): boolean {
            return this.featureStore.isEnabled('INTERACT');
        },
        displayAdminButton(): boolean {
            return this.adminRoutes.has(this.$route.name ?? '') && (this.userStore.isAdmin || this.userStore.isManager);
        },
        displayNavButtons(): boolean {
            return this.navButtonRoutes.has(this.$route.name ?? '');
        },
        displayAnalyticsButton(): boolean {
            return this.userStore.canViewAnalytics && this.analyticsEnabledRoutes.has(this.$route.name ?? '');
        },
        displayBackToWorklistButton(): boolean {
            return !this.worklistRoutes.has(this.$route.name ?? '');
        },
        isInboxEnabled(): boolean {
            return this.featureStore.isEnabled('UPDATE_QUERY_TO_INBOX');
        },
        displayQueriesButton(): boolean {
            return this.displayNavButtons && this.isInteractEnabled;
        },
        isHideCompleteReviewFlagEnabled(): boolean {
            return this.featureStore.isEnabled('HIDE_COMPLETE_REVIEW_PA');
        },
        isExternalPhysicianAdvisor(): boolean {
            return this.userStore.isExternalPhysicianAdvisor;
        },
        //display the complete review button when
        // HIDE_COMPLETE_REVIEW_PA is true and role is Physician advisor and interact is disabled
        // HIDE_COMPLETE_REVIEW_PA is true and func~claim is externalPhysicianAdvisor (role external physician) and interact is enabled
        // HIDE_COMPLETE_REVIEW_PA is false for everyone
        showCompleteReviewButton(): boolean {
            return (
                (this.isExternalPhysicianAdvisor && this.isInteractEnabled && this.isHideCompleteReviewFlagEnabled) ||
                (this.isPhysicianAdvisor && !this.isInteractEnabled && this.isHideCompleteReviewFlagEnabled) ||
                this.isUmNurse ||
                !this.isHideCompleteReviewFlagEnabled
            );
        },
    },
});
