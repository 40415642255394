
import { EventBus, Events } from '@/components/shared/event-bus';
import Loadable from '@/components/shared/Loadable.vue';
import VisitSummariesDetailView from '@/components/visit/gen-ai/VisitSummariesDetailView.vue';
import VisitSummary from '@/components/visit/patient-summary/soi-ios/VisitSummary.vue';
import { VisitSummaryApp } from '@/services/VisitSummaryApp';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitStore } from '@/stores/VisitStore';
import { retry } from 'ts-retry';
import Vue from 'vue';

export default Vue.extend({
    name: 'VisitSummaryGenAI',
    components: {
        Loadable,
        VisitSummariesDetailView,
        VisitSummary,
    },
    data: () => ({
        visitStore: useVisitStore(),
        VisitSummaryApp,
        isLoading: true,
        displaySoiIos: false,
        disableBackButton: false,
        featureStore: useFeatureStore(),
    }),
    computed: {
        componentHeightClass() {
            return this.featureStore.isEnabled('PREDICTION_REDESIGN') ? 'full-height' : 'shared-height';
        },
    },
    created() {
        EventBus.$on(Events.DECLINE_GENAI_USER_TERMS, this.handleDeclinedGenAIUserTermsEvent);
        EventBus.$on(Events.GENAI_MFE_REGISTRATION_COMPLETE, this.handleGenAIMFERegistrationCompleteEvent);
    },
    mounted() {
        if (this.visitStore.isVisitSummaryAppLoaded) {
            this.isLoading = false;
        }
    },
    beforeDestroy() {
        EventBus.$off(Events.GENAI_MFE_REGISTRATION_COMPLETE, this.handleGenAIMFERegistrationCompleteEvent);
        EventBus.$off(Events.DECLINE_GENAI_USER_TERMS, this.handleDeclinedGenAIUserTermsEvent);

        this.visitStore.$subscribe(() => {
            if (this.visitStore.isVisitSummaryAppLoaded) {
                this.isLoading = false;
            }
        });
    },
    methods: {
        async toggleView() {
            this.displaySoiIos = !this.displaySoiIos;
            if (this.displaySoiIos) {
                await VisitSummaryApp.hideApp(VisitSummaryApp.visitSummaryAppName);
            }
        },
        handleGenAIMFERegistrationCompleteEvent() {
            this.isLoading = false;
            retry(
                () =>
                    VisitSummaryApp.mountApp(
                        VisitSummaryApp.visitSummaryAppName,
                        VisitSummaryApp.visitSummaryAppContainerId,
                        VisitSummaryApp.appId + VisitSummaryApp.visitSummaryAppName
                    ),
                { delay: 300, maxTry: 10 }
            );
        },
        handleDeclinedGenAIUserTermsEvent() {
            this.displaySoiIos = true;
            this.disableBackButton = true;
        },
    },
});
