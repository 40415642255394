
import ConfidenceIcon from '@/components/shared/ConfidenceIcon.vue';
import InfoButton from '@/components/shared/InfoButton.vue';
import PercentageLineBar from '@/components/shared/PercentageLineBar.vue';
import {
    admitStatusDischargedDisclaimer,
    getAdmitStatusConfidenceColor,
    getRoundedPercentage,
    nullishCoalesce,
    predictedAdmitStatusAboveThresholdDisclaimer,
    predictedAdmitStatusAboveThresholdDisclaimerAdmit,
    predictedAdmitStatusBelowThresholdDisclaimer,
    predictedAdmitStatusBelowThresholdDisclaimerAdmit,
    predictedAdmitStatusIPODisclaimer,
    visitPredictedStatusDisclaimer,
} from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import VisitPredictedStatusHistory from '@/components/visit/patient-summary/predicted-status/VisitPredictedStatusHistory.vue';
import { AdmitStatusWithConfidence, PredictedAdmitStatus } from '@/models';
import { AdmitStatus } from '@/models/shared/PredictedAdmitStatusConfidence.model';
import { useFeatureStore } from '@/stores/FeatureStore';
import { Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitPredictedStatus',
    components: {
        InfoButton,
        ConfidenceIcon,
        PercentageLineBar,
        VerticalField,
        VisitPredictedStatusHistory,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
    },
    data: () => ({
        predictedAdmitStatusAboveThresholdDisclaimer,
        predictedAdmitStatusBelowThresholdDisclaimer,
        predictedAdmitStatusAboveThresholdDisclaimerAdmit,
        predictedAdmitStatusBelowThresholdDisclaimerAdmit,
        predictedAdmitStatusIPODisclaimer,
        visitPredictedStatusDisclaimer,
        predictions: [] as AdmitStatusWithConfidence[],
        predictedAdmitStatusWithConfidence: {
            admitStatus: '',
            confidencePercentage: 0,
        } as AdmitStatusWithConfidence,
        showHistory: false,
        admitStatus: AdmitStatus,
        featureStore: useFeatureStore(),
    }),
    computed: {
        isDischarged(): boolean {
            return this.visit.admitStatus === AdmitStatus.DISCHARGED || !!this.visit.dischargeDate;
        },
        statusText(): string {
            if (this.isDischarged) {
                return admitStatusDischargedDisclaimer;
            }
            if (this.visit.inpatientOnly) {
                return predictedAdmitStatusIPODisclaimer;
            }
            if (this.visit.predictedAdmitStatus == this.visit.admitStatus) {
                // status has not changed so this is below threshold
                if (this.visit.admitStatus === AdmitStatus.EMERGENCY) {
                    return predictedAdmitStatusBelowThresholdDisclaimerAdmit;
                } else {
                    return predictedAdmitStatusBelowThresholdDisclaimer;
                }
            } else {
                if (this.predictedAdmitStatus == PredictedAdmitStatus.ADMIT) {
                    return predictedAdmitStatusAboveThresholdDisclaimerAdmit;
                } else {
                    return predictedAdmitStatusAboveThresholdDisclaimer;
                }
            }
        },
        hidePredictionStatusTile(): boolean {
            return this.featureStore.isEnabled('PREDICTION_REDESIGN');
        },
        modelPredictedAdmitStatus(): string {
            const confidences = {
                [PredictedAdmitStatus.DISCHARGE]: this.visit.currentAdmitStatusPrediction?.dischargeConfidence ?? 0.0,
                [PredictedAdmitStatus.ADMIT]: this.visit.currentAdmitStatusPrediction?.admitConfidence ?? 0.0,
                [PredictedAdmitStatus.INPATIENT]: this.visit.currentAdmitStatusPrediction?.inpatientConfidence ?? 0.0,
                [PredictedAdmitStatus.OBSERVATION]: this.visit.currentAdmitStatusPrediction?.observationConfidence ?? 0.0,
            };
            const maxPrediction = Object.entries(confidences).reduce((acc, entry) => (entry[1] > acc[1] ? entry : acc));
            return maxPrediction[0];
        },
        predictedAdmitStatus(): string {
            if (this.isDischarged) {
                return AdmitStatus.DISCHARGED;
            }
            return this.visit.predictedAdmitStatus ?? 'Predicted Status Not Available';
        },
        isPredictedAdmitStatusBelowThreshold(): boolean {
            return this.visit.predictedAdmitStatus == this.visit.admitStatus;
        },
        hasNoPredictions(): boolean {
            return !this.visit.predictedAdmitStatusConfidence;
        },
    },
    created() {
        if (this.visit.predictedAdmitStatus) {
            this.predictedAdmitStatusWithConfidence = {
                admitStatus: this.visit.predictedAdmitStatus,
                confidencePercentage: getRoundedPercentage(this.visit.predictedAdmitStatusConfidence),
            };
        }
    },
    methods: {
        nullishCoalesce,
        getAdmitStatusConfidenceColor,
    },
});
