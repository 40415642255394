import { Maybe } from 'generated/graphql/graphql';
import { NULLABLE } from './types';

export const getRoundedPercentage = (value: NULLABLE<number>): number => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) return 0;
    return Math.round(numberValue * 100);
};

export const getFlooredPercentage = (value: NULLABLE<number>): number => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) return 0;
    return Math.floor(numberValue * 100);
};

export const filterValidPredictionStatuses = (
    isPredictionRedesignEnabled: boolean,
    isEdModelEnabled: boolean,
    predictedAdmitStatuses?: Maybe<string[]>
): string[] | undefined => {
    const redesignedStatuses = ['Upgrade', 'Downgrade'];
    if (isPredictionRedesignEnabled) {
        if (isEdModelEnabled) {
            redesignedStatuses.push('Emergency');
        }
        return predictedAdmitStatuses?.filter((admitStatus) => redesignedStatuses.includes(admitStatus));
    } else {
        if (!isEdModelEnabled) {
            redesignedStatuses.push('Emergency', 'Admit');
        }
        return predictedAdmitStatuses?.filter((admitStatus) => !redesignedStatuses.includes(admitStatus));
    }
};
