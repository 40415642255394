
import ConfidenceIndicatorCard from '@/components/shared/ConfidenceIndicatorCard.vue';
import { getPredictedOutcome, getRoundedPercentage } from '@/components/shared/utils';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ConfidenceIndicatorBadge',
    components: {
        ConfidenceIndicatorCard,
    },
    props: {
        confidence: { type: Number, required: true, default: 0 },
        admitStatus: { type: String, required: true },
        predictedStatus: { type: String, required: false, default: '' },
        showPopupCard: { type: Boolean, required: false, default: true },
        isIpo: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        hover: false,
    }),
    computed: {
        status(): string {
            return getPredictedOutcome(this.admitStatus, this.predictedStatus);
        },
        color(): string {
            return this.status === 'Upgrade' ? 'success95' : 'informational87';
        },
        icon(): string {
            return this.status === 'Upgrade' ? 'fa-arrow-up' : 'fa-arrow-down';
        },
    },
    methods: {
        getRoundedPercentage,
    },
});
