import { render, staticRenderFns } from "./WorklistFilters.vue?vue&type=template&id=387e2699&scoped=true&"
import script from "./WorklistFilters.vue?vue&type=script&lang=ts&"
export * from "./WorklistFilters.vue?vue&type=script&lang=ts&"
import style0 from "./WorklistFilters.vue?vue&type=style&index=0&id=387e2699&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387e2699",
  null
  
)

export default component.exports