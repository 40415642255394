
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import { DateSelection, formatDate, formatDateTime, withDefault } from '@/components/shared/utils';
import VisitSummarySection from '@/components/visit/patient-summary/soi-ios/VisitSummarySection.vue';
import { getVisitSummary } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import { Query, VisitSummary } from 'generated/graphql/graphql';
import { mapState } from 'pinia';
import Vue from 'vue';

enum Tab {
    PRIMARY,
    SECONDARY,
}

export default Vue.extend({
    name: 'VisitSummary',
    components: {
        VisitSummarySection,
        FeatureToggle,
    },
    props: {
        disableBackButton: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        visitStore: useVisitStore(),
        userStore: useUserStore(),
        soi: [] as VisitSummary[],
        ios: [] as VisitSummary[],
        selectedTab: Tab.PRIMARY,
        featureStore: useFeatureStore(),
        DateSelection,
        Tab,
    }),
    computed: {
        componentHeightClass(): string {
            return this.featureStore.isEnabled('PREDICTION_REDESIGN') ? 'full-height' : 'shared-height';
        },
        dateRangeText(): string {
            if (this.visitStore.dateSelection) {
                switch (this.visitStore.dateSelection) {
                    case DateSelection.NEW_SINCE_LAST_REVIEW:
                        return DateSelection.NEW_SINCE_LAST_REVIEW + ' (' + formatDateTime(this.visitStore.visit?.lastReviewDate) + ')';
                    case DateSelection.LAST_24_HOURS:
                        return DateSelection.LAST_24_HOURS;
                    case DateSelection.LAST_48_HOURS:
                        return DateSelection.LAST_48_HOURS;
                }
            }
            if (this.visitStore.startDate && this.visitStore.endDate) {
                return formatDate(this.visitStore.startDate) + ' - ' + formatDate(this.visitStore.endDate);
            }
            const latestPredictionDate = this.getLatestCreatedDateFromSummary();
            return 'Latest prediction at ' + withDefault(formatDateTime(latestPredictionDate));
        },
        ...mapState(useVisitStore, ['startDate', 'endDate']),
    },
    watch: {
        async startDate() {
            this.loadData(parseInt(this.$route.params.id));
        },
        async endDate() {
            this.loadData(parseInt(this.$route.params.id));
        },
    },
    created() {
        this.loadData(parseInt(this.$route.params.id));
    },
    methods: {
        formatDate,
        formatDateTime,
        withDefault,
        async loadData(id: number): Promise<void> {
            const response = await this.$apollo.query<Query>({
                query: getVisitSummary,
                variables: {
                    id: id,
                    filter: {
                        startDate: this.visitStore.startDate,
                        endDate: this.visitStore.endDate,
                    },
                },
                fetchPolicy: 'no-cache',
            });

            if (!response.data.visit?.summaries) return;
            this.soi = [];
            this.ios = [];

            for (const summary of response.data.visit.summaries) {
                switch (summary.type) {
                    case 'SOI':
                        this.soi.push(summary);
                        break;
                    case 'IOS':
                        this.ios.push(summary);
                        break;
                    default:
                        console.log('Unknown summary type: ' + summary.type);
                        break;
                }
            }
        },
        switchTab(tab: Tab): void {
            this.selectedTab = tab;
        },
        getLatestCreatedDateFromSummary(): string | null {
            const soiTimestamp = new Date(this.soi[0]?.createdDate ?? 0);
            const iosTimestamp = new Date(this.ios[0]?.createdDate ?? 0);
            const maxTimestamp = Math.max(+soiTimestamp, +iosTimestamp);

            if (maxTimestamp === 0) return null;

            return new Date(maxTimestamp).toISOString();
        },
    },
});
