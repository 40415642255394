
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import Vue from 'vue';

export default Vue.extend({
    name: 'InboxButton',
    components: {
        SideBarButton,
    },
    props: {
        expanded: Boolean,
    },
    methods: {
        displayInbox() {
            this.$router.push({ name: 'inbox' });
        },
    },
});
