export enum PrincipalDiagnosisCodeError {
    PRINCIPAL_CODE_REQUIRED = 'Principal code is required',
    PRINCIPAL_DUPLICATES_OTHER = 'Principal code duplicates another code',
    PRINCIPAL_INVALID_FORMAT = 'Principal code must be in ICD-10 format',
    PRINCIPAL_INVALID_CODE = 'Principal code is not a valid ICD-10 code',
}

export enum OtherDiagnosisCodeError {
    OTHER_INVALID_CODE = 'Some Diagnosis codes are not valid ICD-10 codes',
    OTHER_INVALID_FORMAT = 'Diagnosis code must be in ICD-10 format',
    OTHER_DUPLICATES_PRINCIPAL = 'Diagnosis code duplicates the principal code',
    OTHER_DUPLICATES_OTHER = 'Diagnosis code duplicates another code',
    OTHER_LIMIT_EXCEEDED = 'Only 11 other diagnosis codes are allowed',
}

export const PRINCIPAL_ICD10_TYPE = 'ABK';
export const OTHER_ICD10_TYPE = 'ABF';
