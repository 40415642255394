
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ConfidenceIndicatorCard',
    props: {
        confidence: { type: Number, required: true },
        status: { type: String, required: true },
        isIpo: { type: Boolean, required: false, default: false },
    },
    computed: {
        color(): string {
            return this.status === 'Upgrade' ? 'success95' : 'informational87';
        },
    },
});
