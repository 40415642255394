
import { Role } from '@/auth/role.enum';
import AdmitDates from '@/components/shared/AdmitDates.vue';
import AuthExpIcon from '@/components/shared/AuthExpIcon.vue';
import ClinicalsDueIcon from '@/components/shared/ClinicalsDueIcon.vue';
import ConfidenceIcon from '@/components/shared/ConfidenceIcon.vue';
import DenialIcon from '@/components/shared/DenialIcon.vue';
import DischargeIcon from '@/components/shared/DischargeIcon.vue';
import EditableTextField from '@/components/shared/EditableTextField.vue';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import InpatientOnlyIcon from '@/components/shared/InpatientOnlyIcon.vue';
import MultiDatePicker from '@/components/shared/MultiDatePicker.vue';
import NextReviewIcon from '@/components/shared/NextReviewIcon.vue';
import NoPredictionIcon from '@/components/shared/NoPredictionIcon.vue';
import ScoreDots from '@/components/shared/ScoreDots.vue';
import {
    abbreviateAdmitStatus,
    formatDate,
    formatDateTime,
    formatDateWithFullYear,
    formatFacility,
    formatLocation,
    formatPersonName,
    formatTime,
    nullishCoalesce,
    optionalChain,
    withDefault,
} from '@/components/shared/utils';
import VisitOwnerSelector from '@/components/shared/VisitOwnerSelector.vue';
import { AuthorizationDetailsManager } from '@/components/visit/AuthorizationDetailsManager';
import FailedFaxIcon from '@/components/worklist/FailedFaxIcon.vue';
import PredictionStatus from '@/components/worklist/PredictionStatus.vue';
import WorklistReadmissionButton from '@/components/worklist/WorklistReadmissionButton.vue';
import { ReviewOutcome, ReviewType } from '@/models';
import { ADULT_AGE } from '@/models/visit/visit-summary/VisitPatientAge.model';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { trace } from '@opentelemetry/api';
import { Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';
import VClamp from 'vue-clamp';

export default Vue.extend({
    name: 'WorklistItem',
    components: {
        FeatureToggle,
        ConfidenceIcon,
        ScoreDots,
        VisitOwnerSelector,
        WorklistReadmissionButton,
        VClamp,
        DischargeIcon,
        ClinicalsDueIcon,
        FailedFaxIcon,
        NextReviewIcon,
        NoPredictionIcon,
        AdmitDates,
        InpatientOnlyIcon,
        AuthExpIcon,
        MultiDatePicker,
        EditableTextField,
        DenialIcon,
        PredictionStatus,
    },
    props: {
        visit: Object as PropType<Visit>,
        clinicalsDue: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        expanded: false,
        userStore: useUserStore(),
        umNurseType: Role.UM_NURSE,
        physicianAdvisorType: Role.PHYSICIAN_ADVISOR,
        ADULT_AGE,
        authorizationDetailsManager: new AuthorizationDetailsManager(),
        // need datesDisabled to be in data to be reactive - changes to the wrapper class not triggering rerender :-(
        datesDisabled: false,
        ReviewType,
        ReviewOutcome,
        featureStore: useFeatureStore(),
    }),
    computed: {
        isUmNurse(): boolean {
            return this.userStore.currentRole === Role.UM_NURSE;
        },
        showCompletedIcon(): boolean {
            return (
                !!this.visit.lastReviewDate &&
                this.visit.lastReviewOutcome === ReviewOutcome.COMPLETE &&
                this.visit.lastReviewType === ReviewType.FIRST_LEVEL
            );
        },
        showDoctorIcon(): boolean {
            return (
                !!this.visit.lastReviewDate &&
                this.visit.lastReviewOutcome === ReviewOutcome.COMPLETE &&
                (this.visit.lastReviewType === ReviewType.SECOND_LEVEL ||
                    this.visit.lastReviewType === ReviewType.INTERACT_SLR ||
                    this.visit.lastReviewType === ReviewType.PEER_TO_PEER)
            );
        },
        showEscalatedIcon(): boolean {
            return !!this.visit.lastReviewDate && this.visit.lastReviewOutcome === ReviewOutcome.ESCALATE;
        },
        caseButtonText(): string {
            return this.visit.lastReviewOutcome === ReviewOutcome.COMPLETE ? 'VIEW CASE' : 'REVIEW CASE';
        },
        showClinicalsDue(): boolean {
            return this.visit && this.clinicalsDue;
        },
        getReviewer(): string {
            return this.getReviewerText();
        },
        getStatusTooltipText(): string {
            if (this.showCompletedIcon || this.showDoctorIcon) {
                return 'Case Reviewed by ' + this.getReviewerText() + ' at ' + withDefault(formatDateTime(this.visit.lastReviewDate));
            }
            if (this.showEscalatedIcon) {
                return 'Case Escalated by ' + this.getReviewerText() + ' at ' + withDefault(formatDateTime(this.visit.lastReviewDate));
            }
            return '';
        },
        showConfidenceIcon(): boolean {
            if (this.visit.admitStatus === this.visit.predictedAdmitStatus && !this.visit.inpatientOnly) {
                return false;
            }
            return true;
        },
        showNextReviewTodayBadge(): boolean {
            return !!this.visit.nextReviewDate;
        },
        showNoPredictionIcon(): boolean {
            return this.visit.predictedAdmitStatus == null;
        },
        showInpatientOnlyBadge(): boolean {
            return !!this.visit.inpatientOnly;
        },
        showPredictionRedesignedUI(): boolean {
            return this.featureStore.isEnabled('PREDICTION_REDESIGN');
        },
    },
    mounted() {
        this.authorizationDetailsManager.initialize(
            this.visit.id,
            this.visit.payerAuthorization?.authNumber ?? undefined,
            this.visit.payerAuthorization?.startDate ?? undefined,
            this.visit.payerAuthorization?.endDate ?? undefined
        );
        this.datesDisabled = !this.authorizationDetailsManager.authNumber;
    },
    methods: {
        withDefault,
        formatPersonName,
        formatDate,
        formatDateTime,
        formatDateWithFullYear,
        formatTime,
        formatFacility,
        formatLocation,
        nullishCoalesce,
        optionalChain,
        abbreviateAdmitStatus,

        // Authorization Detail Manager API - START
        updateAuthNumber(authNumber: string) {
            this.authorizationDetailsManager.updateAuthNumber(authNumber);
            this.datesDisabled = !this.authorizationDetailsManager.authNumber;
        },
        expandWorkListItem() {
            const tracer = trace.getTracer('WorkListItem'); // Replace with your tracer name
            const span = tracer.startSpan('custom-click-span', {
                attributes: {
                    'event.type': 'click',
                    'component.name': 'WorklistItem expand',
                },
            });
            this.expanded = !this.expanded;
            span.end();
        },
        updateAuthDates(startDate: string, endDate: string) {
            this.authorizationDetailsManager.updateDates(startDate, endDate);
        },
        saveAuthNumber() {
            this.authorizationDetailsManager.saveAuthNumber();
            this.datesDisabled = !this.authorizationDetailsManager.authNumber;
        },
        saveAuthDates(startDate: string | undefined, endDate: string | undefined) {
            this.authorizationDetailsManager.saveDates(startDate, endDate);
        },
        // Authorization Detail Manager API - END

        getReviewerText() {
            const formattedReviewer = formatPersonName(this.visit.lastReviewer);
            let reviewer = '';
            if (formattedReviewer !== withDefault('')) {
                reviewer = formattedReviewer + ', ';
            }
            return reviewer;
        },
    },
});
