
import SideBar from '@/components/sidebar/SideBar.vue';
import TitleBar from '@/components/titlebar/TitleBar.vue';
import { getFrontendVersion } from '@/shared/queries';
import { useUserStore } from '@/stores/UserStore';
import Vue from 'vue';
import { Query } from '../generated/graphql/graphql';

export default Vue.extend({
    name: 'App',
    components: {
        SideBar,
        TitleBar,
    },
    data: () => ({
        userStore: useUserStore(),
        frontendVer: null as string | undefined | null,
        isDialogOpen: false,
        timoutInms: 0,
        timeInterval: 0,
    }),
    computed: {
        showNavbar() {
            return this.$route.meta?.showNavbar;
        },
    },
    created() {
        this.getFrontendVersion();
    },
    beforeDestroy() {
        this.$sessionManager.destroy();
    },
    methods: {
        async getFrontendVersion() {
            if (this.userStore.isInitialized) {
                const response = await this.$apollo.query<Query>({ query: getFrontendVersion, fetchPolicy: 'no-cache' });
                let version = response.data?.config?.frontendVersion?.version;
                let inter = response.data?.config?.frontendVersion?.interval;
                this.timoutInms = isNaN(Number(inter)) ? 0 : Number(inter);
                if (this.frontendVer != null && version?.toString() !== this.frontendVer) {
                    this.isDialogOpen = true;
                }
                this.frontendVer = version == undefined ? null : version;
                if (this.timeInterval == 0) this.startInterval();
            } else {
                if (this.userStore.isInitialized === undefined) {
                    await this.userStore.initialize();
                }
                setTimeout(async () => {
                    await this.getFrontendVersion();
                }, 500);
            }
        },
        closeDialog() {
            this.isDialogOpen = false;
            clearInterval(this.timeInterval);
        },
        startInterval() {
            if (this.timoutInms > 0)
                this.timeInterval = window.setInterval(() => {
                    this.getFrontendVersion();
                }, this.timoutInms);
        },
    },
});
