
import AdmitDates from '@/components/shared/AdmitDates.vue';
import AuthExpIcon from '@/components/shared/AuthExpIcon.vue';
import ClinicalsDueIcon from '@/components/shared/ClinicalsDueIcon.vue';
import ConfidenceIndicatorBadge from '@/components/shared/ConfidenceIndicatorBadge.vue';
import DenialIcon from '@/components/shared/DenialIcon.vue';
import DischargeIcon from '@/components/shared/DischargeIcon.vue';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import InpatientOnlyIcon from '@/components/shared/InpatientOnlyIcon.vue';
import NextReviewIcon from '@/components/shared/NextReviewIcon.vue';
import ScoreDots from '@/components/shared/ScoreDots.vue';
import {
    formatDate,
    formatDateTime,
    formatFacility,
    formatLocation,
    formatPersonName,
    nullishCoalesce,
    withDefault,
} from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import GlobalDateTimeFilter from '@/components/visit/info-bar/GlobalDateTimeFilter.vue';
import VisitCardButton from '@/components/visit/info-bar/VisitCardButton.vue';
import { VisitInfoBarField } from '@/models';
import { useVisitStore } from '@/stores/VisitStore';
import { Visit } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'VisitInfoBar',
    components: {
        ScoreDots,
        VisitCardButton,
        DischargeIcon,
        ClinicalsDueIcon,
        ConfidenceIndicatorBadge,
        NextReviewIcon,
        VerticalField,
        AdmitDates,
        FeatureToggle,
        GlobalDateTimeFilter,
        InpatientOnlyIcon,
        AuthExpIcon,
        DenialIcon,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
        displayFields: { type: Set<VisitInfoBarField>, required: true },
    },
    data: () => ({
        VisitInfoBarField,
        visitStore: useVisitStore(),
    }),
    computed: {
        disableDateTimeFilter(): boolean {
            return !this.visitStore.isVisitSummaryAppLoaded;
        },
    },
    methods: {
        withDefault,
        formatPersonName,
        formatDateTime,
        formatDate,
        formatFacility,
        formatLocation,
        nullishCoalesce,
        saveDates(startDate: string | undefined, endDate: string | undefined) {
            this.visitStore.$patch({
                authStartDate: startDate ?? undefined,
                authEndDate: endDate ?? undefined,
            });
        },
    },
});
