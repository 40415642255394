
import ConfidenceIndicatorBadge from '@/components/shared/ConfidenceIndicatorBadge.vue';
import NoPredictionIcon from '@/components/shared/NoPredictionIcon.vue';
import { abbreviateAdmitStatus } from '@/components/shared/utils';
import { Visit } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'PredictionStatus',
    components: {
        ConfidenceIndicatorBadge,
        NoPredictionIcon,
    },
    props: {
        visit: { type: Object as PropType<Visit>, required: true },
    },
    computed: {
        showNoPredictionIcon(): boolean {
            return this.visit.predictedAdmitStatus == null;
        },
        showConfidenceIcon(): boolean {
            return !!this.visit.predictedAdmitStatus && this.visit.admitStatus !== this.visit.predictedAdmitStatus;
        },
        showPredictedStatus(): string {
            const { admitStatus, predictedAdmitStatus } = this.visit;
            if (!predictedAdmitStatus) {
                return '/--';
            }
            return admitStatus === predictedAdmitStatus ? `/${abbreviateAdmitStatus(predictedAdmitStatus)}` : '';
        },
    },
    methods: {
        abbreviateAdmitStatus,
    },
});
