
import { OtherDiagnosisCodeError, PrincipalDiagnosisCodeError } from '@/models/visit/VisitDiagnosisCodes.model';
import { useVisitStore } from '@/stores/VisitStore';
import Vue from 'vue';
import { icd10Regex } from './icd10Regex';

export default Vue.extend({
    name: 'DiagnosisPane',
    data: () => ({
        newOtherDiagnosis: '',
        principalDiagnosisErrorCode: null as PrincipalDiagnosisCodeError | null,
        otherDiagnosisErrorCode: null as OtherDiagnosisCodeError | null,
        visitStore: useVisitStore(),
    }),
    computed: {
        invalidData(): string[] {
            return this.visitStore.invalidDiagnosisCodes;
        },
        principalDiagnosisError(): string {
            return this.principalDiagnosisErrorCode || '';
        },
        otherDiagnosisError(): string {
            return this.otherDiagnosisErrorCode || '';
        },
        principalDiagnosisValid(): boolean {
            return !this.principalDiagnosisErrorCode;
        },
        otherDiagnosisValid(): boolean {
            return !this.otherDiagnosisErrorCode;
        },
        invalidOtherCodes(): boolean {
            // If the count of errors excluding primary code is > 1
            return this.visitStore.invalidDiagnosisCodes.filter((code) => code != this.visitStore.principalDiagnosis).length > 0;
        },
    },
    watch: {
        invalidData(invalidDiagnosisCodes) {
            this.principalDiagnosisErrorCode = null;
            this.otherDiagnosisErrorCode = null;
            if (invalidDiagnosisCodes.length > 0) {
                if (invalidDiagnosisCodes.some((item: string) => item === this.visitStore.principalDiagnosis)) {
                    this.principalDiagnosisErrorCode = PrincipalDiagnosisCodeError.PRINCIPAL_INVALID_CODE;
                }
            }
        },
    },
    async created() {
        await this.visitStore.fetchDiagnosisCodes();
    },
    methods: {
        handlePrincipalChange(): void {
            this.principalDiagnosisErrorCode = null;
            const principalCode = this.visitStore.principalDiagnosis.toLocaleUpperCase().trim();
            if (principalCode === '') {
                this.principalDiagnosisErrorCode = PrincipalDiagnosisCodeError.PRINCIPAL_CODE_REQUIRED;
                return;
            }
            if (!icd10Regex.test(principalCode)) {
                this.principalDiagnosisErrorCode = PrincipalDiagnosisCodeError.PRINCIPAL_INVALID_FORMAT;
                return;
            }
            if (this.visitStore.otherDiagnoses.includes(principalCode)) {
                this.principalDiagnosisErrorCode = PrincipalDiagnosisCodeError.PRINCIPAL_DUPLICATES_OTHER;
                return;
            }
            this.visitStore.principalDiagnosis = principalCode;
        },
        addOtherDiagnosis() {
            this.otherDiagnosisErrorCode = null;
            if (this.newOtherDiagnosis === '') return;
            if (this.visitStore.otherDiagnoses.length >= 11) {
                this.otherDiagnosisErrorCode = OtherDiagnosisCodeError.OTHER_LIMIT_EXCEEDED;
                return;
            }
            const code = this.newOtherDiagnosis.trim().toLocaleUpperCase();
            if (!icd10Regex.test(code)) {
                this.otherDiagnosisErrorCode = OtherDiagnosisCodeError.OTHER_INVALID_FORMAT;
                return;
            }
            if (code === this.visitStore.principalDiagnosis) {
                this.otherDiagnosisErrorCode = OtherDiagnosisCodeError.OTHER_DUPLICATES_PRINCIPAL;
                return;
            }
            if (this.visitStore.otherDiagnoses.includes(code)) {
                this.otherDiagnosisErrorCode = OtherDiagnosisCodeError.OTHER_DUPLICATES_OTHER;
                return;
            }
            this.visitStore.otherDiagnoses.push(code);
            this.newOtherDiagnosis = '';
        },
        removeOtherDiagnosis(index: number) {
            this.visitStore.otherDiagnoses.splice(index, 1);
        },
        clearAllDiagnosis() {
            this.visitStore.otherDiagnoses = [];
            this.visitStore.invalidDiagnosisCodes = [];
        },
    },
});
