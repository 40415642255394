
import { formatDate, formatTime, withDefault } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import VisitTimelineDetailView from '@/components/visit/timeline/VisitTimelineDetailView.vue';
import { ClinicalDocumentItemType, DrawerType, VisitSummaryItemType, VisitTimelineEventDetails } from '@/models';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { MedicationAdministration, MedicationOrder, Query } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitTimelineMedicationsDetailView',
    components: {
        VisitTimelineDetailView,
        AddToClinicalSummaryButton,
    },
    props: {
        selectedEvent: {
            type: Object as PropType<VisitTimelineEventDetails>,
            required: true,
        },
    },
    data: () => ({
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        medicationAdministrations: [] as MedicationAdministration[],
        medicationOrders: [] as MedicationOrder[],
        visitDrawerStore: useVisitDrawerStore(),
        featureStore: useFeatureStore(),
        isLoading: false,
        hover: false,
        VisitSummaryItemType,
    }),
    computed: {
        header(): string {
            return this.selectedEvent.header;
        },
        subheader(): string {
            return `in use on ${formatDate(this.selectedEvent.timestamp)}`;
        },
        isViewTrendFeatureEnabled(): boolean {
            return this.featureStore.isEnabled('LAB_MED_HISTORY');
        },
    },
    watch: {
        $props: {
            immediate: true,
            deep: true,
            handler() {
                this.getMedications(this.selectedEvent);
            },
        },
    },
    methods: {
        formatDate,
        formatTime,
        withDefault,
        trendsTooltipText(genericName: string) {
            return genericName ? 'View Trend' : 'No trends available';
        },
        getMedications(event: VisitTimelineEventDetails): Promise<void> {
            this.isLoading = true;
            return this.$apollo
                .query<Query>({
                    query: gql`
                        query TimelineMedications($visitId: Int!, $date: String!) {
                            medicationAdministrations(visitId: $visitId, date: $date) {
                                items {
                                    id
                                    name
                                    route
                                    amount
                                    units
                                    date
                                    notes
                                    medicationOrder {
                                        id
                                        drugGenericName
                                    }
                                }
                            }
                            medicationOrders(filter: { visitId: $visitId, date: $date }) {
                                items {
                                    id
                                    name
                                    route
                                    amount
                                    startDate
                                    endDate
                                    orderDate
                                    orderedBy
                                    interval
                                    instructions
                                    drugGenericName
                                }
                            }
                        }
                    `,
                    variables: {
                        visitId: parseInt(this.$route.params.id),
                        date: event.groupedByDate,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.medicationAdministrations = response.data.medicationAdministrations.items;
                    this.medicationOrders = response.data.medicationOrders.items;

                    this.isLoading = false;
                });
        },
        openTrendView(id: string, name: string | undefined, type: string, drugGenericName: string) {
            if (!drugGenericName) return;

            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.MEDICATIONS,
                params: {
                    type: type,
                    referenceId: id,
                    medicationName: name,
                    medicationGenericName: drugGenericName,
                },
            });
        },
    },
});
